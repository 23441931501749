<template>
  <div :class="`d-flex flex-grow-1 align-center ${$vuetify.breakpoint.xs ? 'flex-column':'flex-row'}`">
    <v-text-field
      v-model="code"
      class="fill-width"
      clearable
      :label="$t('components.website.userCart.giftCodeLabel')"
      :disabled="disabled"
    />
    <v-btn
      text
      :small="!$vuetify.breakpoint.xs"
      :x-small="$vuetify.breakpoint.xs"
      :loading="submiting"
      :disabled="disabled || !code || code.length < 2"
      @click="onVerifyGiftCode"
    >
      {{ $t('components.website.userCart.giftCodeSubmit') }}
    </v-btn>
  </div>
</template>

<script>
  import { toEnglishDigits } from '@peynman/press-vue-core/utils/helpers'
  export default {
    name: 'GiftCodeForm',
    props: {
      value: Object,
      disabled: Boolean,
    },
    data: vm => ({
      internalValue: vm.value,
      submiting: false,
      code: vm.value?.code,
    }),
    watch: {
      value () {
        this.internalValue = this.value
        this.code = this.value?.code
      },
    },
    methods: {
      onVerifyGiftCode () {
        this.submiting = true
        this.$store.dispatch('cart/checkGiftCode', toEnglishDigits(this.code))
          .then(json => {
            this.internalValue = json
            this.$store.commit('snackbar/addMessage', {
              message: this.$t('components.website.userCart.giftSuccess'),
              color: 'green',
            })
            this.$emit('input', this.internalValue)
          })
          .catch(err => {
            this.internalValue = null
            this.$emit('input', null)
            this.$store.commit('snackbar/addMessage', {
              message: err.message,
              color: 'red',
            })
          })
          .finally(() => {
            this.submiting = false
          })
      },
    },
  }
</script>
