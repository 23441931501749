<template>
  <v-dialog v-model="dialog" max-width="766px">
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on" />
    </template>
    <v-card
      :dark="theme.website.signing.dark"
      :light="theme.website.signing.light"
      :color="theme.website.signing.background"
    >
      <signup-form>
        <template v-slot:register />
      </signup-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import SignupForm from '../Auth/SignupForm.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'NewSignupDialog',
    components: {
      SignupForm,
    },
    mixins: [
      Themeable,
    ],
    data: vm => ({
      dialog: false,
    }),
  }
</script>
