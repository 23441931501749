<template>
  <website>
    <v-card
      :dark="theme.website.cards.dark"
      :light="theme.website.cards.light"
      :color="theme.website.cards.color"
    >
      <validations-alert
        v-if="hasAlert"
        :color="alertColor"
        :message="alertMessage"
        :type="alertType"
      />
    </v-card>
    <UserCartDetails />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import UserCartDetails from '../components/UserCart/UserCartDetails.vue'
  import ResponseAlerts from '@peynman/press-vue-core/mixins/ResponseAlerts'
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'PurchasingCart',
    components: {
      Website,
      UserCartDetails,
      ValidationsAlert,
    },
    mixins: [
      ResponseAlerts,
      Themeable,
    ],
  }
</script>
