<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-card-title>{{ $t('components.website.userCart.title') }}</v-card-title>
    <v-card-text>
      <user-cart-list :cart="cart" />
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-form
            v-if="isLoggedIn && hasProfile"
            ref="form"
            v-model="valid"
            :class="`d-flex flex-column ${$vuetify.breakpoint.xs ? '':'me-2'}`"
          >
            <user-address-picker
              v-model="address"
              :disabled="submiting"
              @input="onUpdateAddress"
            />
            <v-select
              v-model="delivery"
              :items="cartAvailableDeliveryAgents"
              :label="$t('components.website.userCart.deliveryAgent')"
              :rules="[ getRequiredRule() ]"
              :error-messages="!address ? [ $t('components.website.userCart.chooseAddressFirst') ] : []"
              :loading="loading"
              :disabled="!address || loading || submiting"
              return-object
              @input="onUpdateDeliveryDetails"
            />
            <v-alert
              v-if="deliveryAlert"
              text
              type="warning"
              border="bottom"
              class="my-1 mx-3"
              icon="mdi-alert"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ deliveryAlert }}
                </v-col>
              </v-row>
            </v-alert>
            <bank-gateway-picker
              v-model="gateway"
              :rules="[ getRequiredRule() ]"
              :label="$t('components.website.userCart.bankGateway')"
              :disabled="submiting"
              class="mt-4"
            />
            <gift-code-form
              :disabled="submiting"
            />
          </v-form>
          <div v-else-if="isLoggedIn">
            <v-btn color="primary" to="/me/profile">
              {{ $t('components.website.userCart.fillFormFirst') }}
            </v-btn>
          </div>
        </v-col>
        <v-divider v-if="!$vuetify.breakpoint.xs" vertical />
        <v-col cols="12" sm="6">
          <cart-amount :cart="cart" :selected-delivery="delivery && !loading" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions class="ma-3 d-flex flex-wrap flex-row flex-grow-1 align-center justify-space-between">
      <v-btn
        v-if="isLoggedIn"
        color="success"
        :loading="submiting"
        :disabled="!valid || loading"
        :block="$vuetify.breakpoint.xs"
        @click="onGoToBank"
      >
        {{ $t('components.website.userCart.vetifyCart') }}
      </v-btn>
      <new-signup-dialog
        v-if="!isLoggedIn"
      >
        <template v-slot:activator="{on}">
          <v-btn color="primary" v-on="on">{{ $t('components.website.userCart.registerAndCheckout') }}</v-btn>
        </template>
      </new-signup-dialog>
      <div
        v-if="!isLoggedIn"
        class="d-flex flex-column mt-2"
      >
        <span class="caption">{{ $t('components.website.userCart.alreadyHaveAccount') }}</span>
        <v-dialog max-width="766px">
          <template v-slot:activator="{on}">
            <v-btn text small v-on="on">{{ $t('components.website.userCart.signin') }}</v-btn>
          </template>
          <v-card
            :dark="theme.website.signing.dark"
            :light="theme.website.signing.light"
            :color="theme.website.signing.background"
          >
            <signin-form />
          </v-card>
        </v-dialog>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
  import BankGatewayPicker from '@peynman/press-vue-admin/components/Inputs/BankGatewayPicker/BankGatewayPicker.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserCartList from './UserCartList.vue'
  import UserPurchasingCart from '@peynman/press-vue-core/mixins/UserPurchasingCart'
  import NewSignupDialog from './NewSignupDialog.vue'
  import SigninForm from '../Auth/SigninForm.vue'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import UserAddressPicker from './UserAddressPicker.vue'
  import GiftCodeForm from './GiftCodeForm.vue'
  import CartAmount from './CartAmount.vue'
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'

  export default {
    name: 'UserCartDetails',
    components: {
      BankGatewayPicker,
      UserCartList,
      NewSignupDialog,
      SigninForm,
      UserAddressPicker,
      GiftCodeForm,
      CartAmount,
    },
    mixins: [
      Themeable,
      UserPurchasingCart,
      UserProfile,
      FormValidations(),
    ],
    data: vm => ({
      submiting: false,
      valid: false,
      address: null,
      gateway: 0,
      delivery: null,
      deliveryAlert: null,
      loading: false,
      showValidate: false,
    }),
    computed: {
      bankGatewayId () {
        if (this.gateway !== null && this.gateway !== undefined) {
          return this.$store.getters['banking/bankGateways']?.[this.gateway]?.id
        }

        return null
      },
    },
    mounted () {
      this.delivery = this.purchasingCart?.data?.delivery_agent
      this.address = this.purchasingCart?.data?.delivery_address_id
    },
    methods: {
      onUpdateAddress () {
        this.delivery = null
        this.onUpdateDeliveryDetails()
      },
      onUpdateDeliveryDetails () {
        this.$nextTick(() => {
          this.loading = true
          this.$store.dispatch('cart/updateDeliveryDetails', {
            addressId: this.address,
            deliveryName: this.delivery?.value,
          }).finally(() => {
            this.loading = false
            this.deliveryAlert = this.delivery?.hint
          })
        })
      },
      onGoToBank () {
        this.$refs.form.validate()

        if (this.valid) {
          this.submiting = true
          this.$store.dispatch('cart/validateCart')
            .then(json => {
              this.$router.push({
                path: '/me/cart/verify/' + this.bankGatewayId,
              })
            })
            .finally(() => {
              this.submiting = false
            })
        }
      },
    },
  }
</script>
