<template>
  <div :class="`d-flex flex-grow-1 align-center ${$vuetify.breakpoint.xs ? 'flex-column':'flex-row'}`">
    <v-autocomplete
      v-model="internalValue"
      :items="availableAddresses"
      :rules="[getRequiredRule()]"
      :label="$t('components.website.userCart.pickAddress')"
      :disabled="disabled"
      class="fill-width"
      @input="$emit('input', internalValue)"
    >
      <template v-slot:item="{item, on}">
        <v-list-item v-on="on">
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-chip label small class="me-2">{{ item.province }}</v-chip>
              <v-chip label small>{{ item.city }}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" max-width="766px" @input="onDialogEvent">
      <template v-slot:activator="{on}">
        <v-btn
          text
          v-on="on"
        >
          <v-icon small class="me-1">mdi-plus-box</v-icon>
          <span>{{ $t('components.website.profileAddresses.addAddress') }}</span>
        </v-btn>
      </template>
      <address-form
        ref="form"
        :loading="loading"
        :dark="theme.website.cards.dark"
        :light="theme.website.cards.light"
        :color="theme.website.cards.color"
        @close="dialog = false"
        @input="onAddAddress"
      />
    </v-dialog>

  </div>
</template>

<script>
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import AddressForm from '@peynman/press-vue-admin/components/Inputs/AddressInput/AddressForm.vue'
  import Iran from '@peynman/press-vue-core/mixins/Iran'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'UserAddressPicker',
    components: {
      AddressForm,
    },
    mixins: [
      FormValidations(),
      UserProfile,
      Iran,
      Themeable,
    ],
    props: {
      value: Number,
      disabled: Boolean,
    },
    data: vm => ({
      internalValue: vm.value,
      dialog: false,
      loading: false,
    }),
    computed: {
      availableAddresses () {
        return this.addresses?.map(a => ({
                    text: a.address,
                    value: a.id,
                    province: this.getProvinceById(a.province_code)?.text,
                    city: this.getCityById(a.province_code, a.city_code)?.text,
                })) ?? []
      },
    },
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      onDialogEvent () {
        if (this.dialog && this.$refs.form) {
          this.$refs.form.resetMap()
        }
      },
      onAddAddress (address) {
        this.loading = true
        this.$store.dispatch('profile/addAddress', address)
          .then(json => {
            this.$refs.form.resetForm()
            this.dialog = false
            this.internalValue = json.id
            this.$emit('input', this.internalValue)
          })
          .catch(err => {
            this.$refs.form.updateFormValidationErrors(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
